// src/components/Failure.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulación de redirección después de unos segundos
    const timer = setTimeout(() => {
      navigate('/'); // Redirigir a la página principal después de 5 segundos
    }, 5000);

    // Limpieza de temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="failure-page">
      <h1>¡Pago Fallido!</h1>
      <p>Lo sentimos, ha ocurrido un error durante el proceso de pago.</p>
      <p>Por favor, intenta nuevamente. Serás redirigido a la página principal en unos segundos...</p>

      <button onClick={() => navigate('/')}>Volver a la página principal</button>
    </div>
  );
};

export default Failure;
