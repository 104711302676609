// src/components/Pending.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Pending = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Simulación de redirección después de unos segundos
    const timer = setTimeout(() => {
      navigate('/'); // Redirigir a la página principal después de 5 segundos
    }, 5000);

    // Limpieza de temporizador cuando el componente se desmonte
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="pending-page">
      <h1>¡Pago Pendiente!</h1>
      <p>Tu pago está siendo procesado. Por favor, espera un momento.</p>
      <p>Serás redirigido a la página principal en unos segundos...</p>

      <button onClick={() => navigate('/')}>Volver a la página principal</button>
    </div>
  );
};

export default Pending;
