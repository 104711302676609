import React, { useState, useEffect } from 'react';
import '../App.css'; // Asegúrate de importar el archivo CSS

const AdminBarraDeProgreso = () => {
  const [porcentaje, setPorcentaje] = useState(0);
  const [successMessage, setSuccessMessage] = useState(''); // Nuevo estado para el mensaje de éxito

  // Obtener el porcentaje actual desde la base de datos
  useEffect(() => {
    const fetchPorcentaje = async () => {
      try {
        const response = await fetch('https://premiosyonivaldez.com:3001/get_porcentaje');
        const data = await response.json();
        setPorcentaje(data.porcentaje);
      } catch (error) {
        console.error('Error al obtener el porcentaje:', error);
      }
    };

    fetchPorcentaje();
  }, []);

  const handleChangePorcentaje = (e) => {
    setPorcentaje(e.target.value);
  };

  const handleGuardarPorcentaje = async () => {
    try {
      await fetch('https://premiosyonivaldez.com:3001/update_porcentaje', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ porcentaje }),
      });
      setSuccessMessage('Porcentaje actualizado correctamente'); // Establecer el mensaje de éxito
      setTimeout(() => setSuccessMessage(''), 3000); // Limpiar el mensaje después de 3 segundos
    } catch (error) {
      console.error('Error al guardar el porcentaje:', error);
    }
  };

  return (
    <div className="admin-barra-progreso">
      <h2>Actualizar Porcentaje</h2>
      <div className="input-button-container">
        <input
          type="number"
          value={porcentaje}
          onChange={handleChangePorcentaje}
          min="0"
          max="100"
          className="porcentaje-input-adm"
        />
        <button onClick={handleGuardarPorcentaje} className="guardar-button">Guardar</button>
      </div>
      {successMessage && (
        <div className="success-message">{successMessage}</div> // Mostrar el mensaje
      )}
    </div>
  );
};

export default AdminBarraDeProgreso;
