import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import ProductWithProgress from './components/ProductWithProgress'; // Importa el nuevo componente
import Login from './components/Login';
import PanelAdmin from './components/PanelAdmin';
import PublicacionAdm from './components/PublicacionAdm';
import AdminBarraDeProgreso from './components/AdminBarraDeProgreso';
import BorrarBase from './components/BorrarBase';
import Sorteo from './components/Sorteo';
import Success from './components/Success';
import Failure from './components/Failure';
import Pending from './components/Pending';
import SorteoActual from './components/SorteoActual';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { ProductProvider } from './contexts/ProductContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <AuthProvider>
      <ProductProvider>
        <Router>
          <div className="App">
            <Header />
            <Routes>
              <Route path="/" element={<><Hero /><ProductWithProgress /></>} /> {/* Cambia aquí */}
              <Route path="/login" element={<Login />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
              <Route path="/pending" element={<Pending />} />
              <Route element={<PrivateRoute />}>
                <Route path="/admin" element={<PanelAdmin />} />
                <Route path="/admin/publicacion" element={<PublicacionAdm />} />
                <Route path="/admin/sorteo" element={<Sorteo />} />
                <Route path="/admin/sorteoactual" element={<SorteoActual />} />
                <Route path="/admin/adminbarradeprogreso" element={<AdminBarraDeProgreso />} />
                <Route path="/admin/borrarbase" element={<BorrarBase />} />
              </Route>
            </Routes>
            <Footer />
          </div>
        </Router>
      </ProductProvider>
    </AuthProvider>
  );
}

export default App;
