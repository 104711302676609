import React, { useEffect, useState } from 'react';
import '../App.css';
import ExcelJS from 'exceljs';

const SorteoActual = () => {
  const [transactions, setTransactions] = useState([]);
  const [offset] = useState(0);
  const limit = 10; // Limitar las transacciones a 10 por carga

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://premiosyonivaldez.com:3001/get_transactions?offset=${offset}&limit=${limit}`);
        if (!response.ok) throw new Error('Error en la respuesta de la red');

        const data = await response.json();
        console.log(`Transacciones recibidas con offset ${offset}:`, data);

        // Limitar el array de transacciones a las primeras 10 que obtenemos
        const limitedTransactions = data.slice(0, limit);

        // Asegurarse de no repetir transacciones, acumulando solo las nuevas
        setTransactions(prevTransactions => {
          // Filtrar las nuevas transacciones para evitar duplicados
          const newTransactions = limitedTransactions.filter(
            newTransaction => !prevTransactions.some(existingTransaction => existingTransaction.transaction_id === newTransaction.transaction_id)
          );
          return [...prevTransactions, ...newTransactions]; // Acumular las transacciones previas con las nuevas
        });
      } catch (error) {
        console.error('Error al obtener transacciones:', error);
      }
    };

    fetchTransactions();
  }, [offset]);

  // Crear un objeto para contar las chances
  const transactionCount = transactions.reduce((acc, transaction) => {
    if (!acc[transaction.transaction_id]) {
      acc[transaction.transaction_id] = { ...transaction, cantidad_chances: 0 };
    }
    acc[transaction.transaction_id].cantidad_chances += 1;
    return acc;
  }, {});

  const uniqueTransactions = Object.values(transactionCount);

  const handleExport = async () => {
    try {
      const response = await fetch('https://premiosyonivaldez.com:3001/get_all_transactions');

      if (!response.ok) {
        throw new Error('Error al obtener las transacciones');
      }

      const allTransactions = await response.json();

      // Crear un objeto para contar las chances
      const transactionCount = allTransactions.reduce((acc, transaction) => {
        if (!acc[transaction.transaction_id]) {
          acc[transaction.transaction_id] = { ...transaction, cantidad_chances: 0 };
        }
        acc[transaction.transaction_id].cantidad_chances += 1;
        return acc;
      }, {});

      const exportData = [];

      Object.values(transactionCount).forEach(transaction => {
        for (let i = 0; i < transaction.cantidad_chances; i++) {
          exportData.push({
            transaction_id: transaction.transaction_id,
            nombre_cliente: transaction.nombre_cliente,
            cuenta_cliente: transaction.cuenta_cliente,
            amount: transaction.amount,
            currency: transaction.currency,
            status: transaction.status,
          });
        }
      });

      // Crear un nuevo libro de Excel
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Transacciones');

      // Definir las cabeceras de las columnas
      worksheet.columns = [
        { header: 'ID Transacción', key: 'transaction_id' },
        { header: 'Nombre Cliente', key: 'nombre_cliente' },
        { header: 'Cuenta Cliente', key: 'cuenta_cliente' },
        { header: 'Monto', key: 'amount' },
        { header: 'Moneda', key: 'currency' },
        { header: 'Estado', key: 'status' },
      ];

      // Llenar la hoja de cálculo con los datos
      exportData.forEach(transaction => {
        worksheet.addRow(transaction);
      });

      // Generar el archivo y descargarlo
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'transacciones.xls';
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error al exportar a Excel:', error);
    }
  };

  return (
    <div className="sorteo-actual-container">
      <h1>Sorteo Actual</h1>

      <table className="transaction-table">
        <thead>
          <tr>
            <th>ID Transacción</th>
            <th>Nombre Cliente</th>
            <th>Cuenta Cliente</th>
            <th>Monto</th>
            <th>Moneda</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {uniqueTransactions.map(transaction => (
            <tr key={transaction.transaction_id}>
              <td>{transaction.transaction_id}</td>
              <td>{transaction.nombre_cliente}</td>
              <td>{transaction.cuenta_cliente}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.currency}</td>
              <td>{transaction.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="export-button" onClick={handleExport}>
        Exportar a Excel
      </button>
    </div>
  );
};

export default SorteoActual;
