// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <p>© 2024 Nicolás Spera. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
