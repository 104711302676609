import React, { useState } from 'react';
import '../App.css'; // Asegúrate de tener el archivo de estilo

export const BorrarBase = () => {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // Nuevo estado para el mensaje de éxito

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://premiosyonivaldez.com:3001/delete_transactions', {
        method: 'POST',
      });

      if (response.ok) {
        setSuccessMessage('¡Base de datos borrada correctamente!'); // Establecer el mensaje de éxito
        setTimeout(() => setSuccessMessage(''), 3000); // Limpiar el mensaje después de 3 segundos
      } else {
        setSuccessMessage('Hubo un error al borrar la base de datos.'); // Establecer mensaje de error si hay un problema
        setTimeout(() => setSuccessMessage(''), 3000); // Limpiar el mensaje de error después de 3 segundos
      }
    } catch (error) {
      setSuccessMessage('Error al borrar la base de datos.'); // Establecer mensaje de error en caso de excepción
      setTimeout(() => setSuccessMessage(''), 3000); // Limpiar el mensaje de error después de 3 segundos
    }
    setIsLoading(false);
    setIsConfirming(false); // Oculta la confirmación tras borrar
  };

  const handleCancel = () => {
    setIsConfirming(false); // Cierra el cuadro de confirmación
  };

  return (
    <div className="center-container">
      {!isConfirming ? (
        <button onClick={() => setIsConfirming(true)} className="btn-delete">
          Borrar Base de Datos
        </button>
      ) : (
        <div className="confirmation-box">
          <h2>¿Estás seguro de borrar la base de datos?</h2>
          <p>Esta acción es irreversible.</p>
          <div className="button-group">
            <button onClick={handleDelete} className="btn-confirm" disabled={isLoading}>
              {isLoading ? 'Borrando...' : 'Sí, borrar'}
            </button>
            <button onClick={handleCancel} className="btn-cancel">
              Cancelar
            </button>
          </div>
        </div>
      )}
      <br></br>
      {successMessage && ( // Mostrar el mensaje de éxito o error
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );
};

export default BorrarBase;
