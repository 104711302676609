// src/components/Hero.js
import React from 'react';
import '../App.css';
import motoganador from '../assets/motoganador.jpeg';
import motosola from '../assets/motosola.jpeg';
import motoyoni from '../assets/motoyoni.jpeg';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-collage">
        <img src={motoganador} alt="Imagen 1" />
        <img src={motosola} alt="Imagen 2" />
        <img src={motoyoni} alt="Imagen 3" />
      </div>
      <div className="hero-content">
        <h1>Gana un Honda Wave con nuestro sorteo!</h1>
        <p>¡La oportunidad que estabas esperando!</p>
        {/* <Link to="/login">
          <button>Comprar ahora</button>
        </Link> */}
      </div>
    </section>
  );
}

export default Hero;
