import React, { useEffect, useState } from 'react';
import '../App.css';
import Confetti from 'react-confetti'; // Asegúrate de tener instalado "react-confetti"

const Sorteo = () => {
  const [transactions, setTransactions] = useState([]);
  const [winner, setWinner] = useState(null);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false); // Estado para manejar el confeti
  const [progress, setProgress] = useState(0); // Barra de progreso

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch('https://premiosyonivaldez.com:3001/get_transactions');
        if (!response.ok) {
          throw new Error('Error en la solicitud: ' + response.statusText);
        }
        const data = await response.json();
        setTransactions(data);
      } catch (error) {
        console.error('Error al obtener las transacciones:', error);
      }
    };

    fetchTransactions();
  }, []);

  // Función para generar el sorteo con animación
  const handleSorteo = () => {
    if (transactions.length > 0) {
      setIsSpinning(true);
      setShowConfetti(false); // Oculta el confeti antes de comenzar
      setWinner(null);
      setProgress(0); // Resetea el progreso

      let spinDuration = 3000;
      let intervalTime = 100;
      let elapsedTime = 0;

      const spinInterval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * transactions.length);
        setCurrentTransaction(transactions[randomIndex].transaction_id);

        elapsedTime += intervalTime;
        setProgress((elapsedTime / spinDuration) * 100); // Actualiza la barra de progreso

        if (elapsedTime >= spinDuration) {
          clearInterval(spinInterval);

          const randomIndex = Math.floor(Math.random() * transactions.length);
          const winnerTransaction = transactions[randomIndex];
          setWinner(winnerTransaction.transaction_id);
          setIsSpinning(false);

          // Muestra confeti de inmediato cuando el número ganador sale
          setShowConfetti(true);
        }
      }, intervalTime);
    }
  };

  return (
    <div className="sorteo-container">
      {showConfetti && <Confetti />} {/* Muestra confeti cuando haya un ganador */}
      <h1>Generar Sorteo</h1>
      <div className="sorteo-section">
        <button className="sorteo-button" onClick={handleSorteo} disabled={isSpinning}>
          {isSpinning ? 'Girando...' : 'Generar Sorteo'}
        </button>

        <div className={`sorteo-display ${isSpinning ? 'spinning' : winner ? 'flash-effect' : ''}`}>
          {isSpinning ? (
            <h2 className="spinning-number">{currentTransaction || '...'}</h2>
          ) : winner ? (
            <div className="sorteo-result zoom-effect">
              <h2>¡El ganador es la transacción #{winner}!</h2>
            </div>
          ) : null}
        </div>

        {/* Barra de progreso */}
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};

export default Sorteo;
