import React, { useEffect, useState } from 'react';
import '../App.css';
import { Wallet } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { useProduct } from '../contexts/ProductContext';
import hondawaveImage from '../assets/hondawave.jpg';
import termsPdf from '../assets/terminos_condiciones.pdf'; // Añade la ruta al PDF
import Slider from 'react-slick'; // Importa Slider para el carrusel
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 

const PUBLIC_KEY = 'APP_USR-c7d2652f-98df-4225-8e1b-db8cfd573f71'; 

initMercadoPago(PUBLIC_KEY);

const Product = () => {
  const { product } = useProduct();
  const [quantity, setQuantity] = useState(1);
  const [preferenceId, setPreferenceId] = useState(null);
  const [readyToPay, setReadyToPay] = useState(false);
  const [imageUrls, setImageUrls] = useState([hondawaveImage]); // Array para las imágenes
  const [termsAccepted, setTermsAccepted] = useState(false); // Estado para el checkbox

  useEffect(() => {
    if (product && product.image_urls) {  
      // Verificar si image_urls es un string y convertirlo a un array
      let imageUrlsArray = [];
  
      if (typeof product.image_urls === 'string') {
        // Si es una cadena, la dividimos por comas
        imageUrlsArray = product.image_urls.split(',');
      } else if (Array.isArray(product.image_urls)) {
        // Si ya es un array, lo usamos directamente
        imageUrlsArray = product.image_urls;
      }
  
       // Crear las URLs completas concatenando la URL base del servidor
       const serverImageUrls = imageUrlsArray.map(url => {
        return `https://premiosyonivaldez.com${url.trim()}`;
      });
  
      // Actualiza el estado con las URLs completas
      setImageUrls(serverImageUrls);
    }
  }, [product]);
  
  
  const changeQuantity = (amount) => {
    setQuantity(prevQuantity => Math.max(prevQuantity + amount, 1));
    setReadyToPay(false);
  };

  const handlePayment = async () => {
    try {
      const unitPrice = parseFloat(product.price);
      if (isNaN(unitPrice)) {
        throw new Error("El precio del producto no es válido.");
      }
  
      // Datos predeterminados para el comprador
      const payerData = {
        first_name: 'Catalina',
        last_name: 'Baez',
        email: 'cata.baez@hotmail.com',
      };
  
      // Asegúrate de que el ID del producto y la referencia externa sean únicos
      const itemData = {
        id: product.id || `PRODUCT_${Date.now()}`,  // Genera un ID único si no está disponible
        title: product.title,
        description: product.description || 'Descripción del producto',
        quantity: quantity,
        unit_price: unitPrice,
        category_id: 'electronics',
      };
  
      const response = await fetch('https://premiosyonivaldez.com:3001/create_preference', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [itemData],  // Información del ítem
          payer: payerData,   // Información del comprador
          externalReference: `ORDER_${Date.now()}`,  // Referencia externa única para cada orden
        }),
      });
  
      if (!response.ok) throw new Error('Fallo al crear la preferencia');
  
      const data = await response.json();
      setPreferenceId(data.preferenceId);
      setReadyToPay(true);
    } catch (error) {
      console.error('Error al crear la preferencia:', error);
    }
  };

  const handleTermsChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const price = parseFloat(product.price);
  const formattedPrice = !isNaN(price) ? price.toFixed(2) : 'N/A';

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="product-container">
      <div className="product-image">
        <Slider {...settings}>
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <img src={imageUrl} alt={`Producto ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="product-details">
        <h1>{product.title}</h1>
        <p>{product.description}</p>
        <h2>${formattedPrice}</h2>
        <div className="quantity-controls">
          <div className="quantity-row">
            <button className="buy-button-top" onClick={() => changeQuantity(-1)}>-</button>
            <span className="quantity-display">{quantity}</span>
            <button className="buy-button-top" onClick={() => changeQuantity(+1)}>+</button>
          </div>
          <div className="quantity-row">
            <button className="buy-button-top" onClick={() => changeQuantity(10)}>+10</button>
            <button className="buy-button-top" onClick={() => changeQuantity(20)}>+20</button>
            <button className="buy-button-top" onClick={() => changeQuantity(50)}>+50</button>
          </div>
        </div>
        <br />
        <h3>Total: ${(price * quantity).toFixed(2)}</h3>
        <br />
        
        {/* Enlace para visualizar el PDF de Términos y Condiciones */}
        <div className="terms-container">
          <input 
            type="checkbox" 
            id="terms-checkbox" 
            checked={termsAccepted} 
            onChange={handleTermsChange} 
          />
          <label htmlFor="terms-checkbox">
            Acepto los <a href={termsPdf} target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
          </label>
        </div>
        <br />
        {/* Botón de pago deshabilitado hasta que se acepten los términos */}
        <button 
          className="buy-button-top" 
          onClick={handlePayment} 
          disabled={!termsAccepted}  // Deshabilitar el botón si no se aceptan los términos
        >
          Pagar ahora
        </button>

        {readyToPay && preferenceId && (
          <Wallet initialization={{ preferenceId }} />
        )}
      </div>
    </div>
  );
};

export default Product;
