import React from 'react';
import ReactDOM from 'react-dom/client'; // Importa `createRoot` desde 'react-dom/client'
import App from './App';

// Obtén el contenedor raíz
const container = document.getElementById('root');

// Crea la raíz utilizando `createRoot`
const root = ReactDOM.createRoot(container);

// Renderiza la aplicación
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
