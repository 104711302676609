// ProductWithProgress.js
import React from 'react';
import Product from './Product'; // Asegúrate de importar el componente Product
import BarraDeProgreso from './BarraDeProgreso'; // Asegúrate de importar el componente BarraDeProgreso

const ProductWithProgress = () => {
  return (
    <div className="product-with-progress">
      <Product />
      <BarraDeProgreso />
    </div>
  );
};

export default ProductWithProgress;
