// BarraDeProgreso.js
import React, { useState, useEffect } from 'react';
import '../App.css'; // Asegúrate de tener un archivo CSS para estilos

const BarraDeProgreso = () => {
  const [porcentaje, setPorcentaje] = useState(0);

  // Simulación de obtener el porcentaje desde la base de datos
  useEffect(() => {
    const fetchPorcentaje = async () => {
      try {
        const response = await fetch('https://premiosyonivaldez.com:3001/get_porcentaje'); // Simulación
        const data = await response.json();
        setPorcentaje(data.porcentaje); // Suponemos que el endpoint devuelve { porcentaje: 50 }
      } catch (error) {
        console.error('Error al obtener el porcentaje:', error);
      }
    };

    fetchPorcentaje();
  }, []);

  return (
    <div className="barra-de-progreso">
      <h2>Porcentaje del Sorteo</h2>
      <div className="progress-container">
        <div className="progress-bar" style={{ width: `${porcentaje}%` }} />
      </div>
      <p>{porcentaje}%</p>
    </div>
  );
};

export default BarraDeProgreso;
