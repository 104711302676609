import React, { useState, useEffect } from 'react';
import '../App.css';
import { useProduct } from '../contexts/ProductContext';

const PublicacionAdm = () => {
  const { product, updateProduct } = useProduct();
  const [id] = useState(1); // Cambia aquí a tu ID predeterminado (ej. 1)
  const [title, setTitle] = useState(product?.title || '');
  const [images, setImages] = useState([]); // Inicializa images como un array vacío
  const [description, setDescription] = useState(product?.description || '');
  const [price, setPrice] = useState(product?.price || 0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (product) {
      setTitle(product.title);
      setImages(product.image_urls || []); // Asegúrate de tener un array de imágenes
      setDescription(product.description);
      setPrice(product.price);
    }
  }, [product]);

  // Función para manejar el cambio de imágenes (subida de imágenes)
  const handleImageChange = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    
    // Subimos varias imágenes si el usuario las selecciona
    for (let i = 0; i < files.length; i++) {
      formData.append('images', files[i]); // Permitir subir varias imágenes
    }

    // Subimos las imágenes al servidor
    fetch('https://premiosyonivaldez.com:3001/upload', {
      method: 'POST',
      body: formData,
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error en la subida de las imágenes');
      }
      return response.json();
    })
    .then((data) => {
      if (data.imageUrls) {
        setImages((prevImages) => {
          const updatedImages = [...prevImages, ...data.imageUrls];
          updateProduct({ image_urls: updatedImages });  // Actualiza el producto en el contexto con las nuevas imágenes
          return updatedImages;  // Devuelve el nuevo array de imágenes
        });
      } else {
        console.error('No se recibieron URLs de las imágenes');
      }
    })
    .catch((error) => console.error('Error al subir las imágenes:', error));
  };

  // Función para guardar los cambios del producto
  const handleSave = () => {
    const updatedProduct = { id, title, image_urls: images, description, price };
    console.log("Producto a actualizar:", updatedProduct);

    fetch('https://premiosyonivaldez.com:3001/update_product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedProduct),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Actualización del producto:', data);
      if (data.error) {
        alert(data.error);
      } else {
        updateProduct(updatedProduct); // Actualiza el producto en el contexto
        setShowConfirmation(true);
        setTimeout(() => setShowConfirmation(false), 3000); // Mensaje de confirmación
      }
    })
    .catch((error) => console.error('Error al actualizar el producto:', error));
  };

  return (
    <div className="publicacion-adm">
      <h1>Publicación del Sorteo</h1>
      <div className="form-group">
        <label>Título:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Imágenes:</label>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageChange} // Al cambiar las imágenes, llamamos a esta función
        />
        {images.length > 0 && (
          <div className="image-carousel">
            {images.map((image, index) => (
              <img key={index} src={image} alt={`Vista previa ${index + 1}`} className="image-preview" />
            ))}
          </div>
        )}
      </div>
      <div className="form-group">
        <label>Descripción:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>Precio:</label>
        <input
          type="number"
          value={price}
          onChange={(e) => {
            const value = e.target.value;
            setPrice(value === '' ? '' : parseFloat(value));
          }}
        />
      </div>
      <button onClick={handleSave}>Guardar Cambios</button>

      {showConfirmation && (
        <div className="confirmation-message">
          Publicación Actualizada
        </div>
      )}
    </div>
  );
};

export default PublicacionAdm;
