import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const PanelAdmin = () => {
  const navigate = useNavigate();

  const handleNavigateToPublicacion = () => {
    navigate('/admin/publicacion');
  };

  const handleNavigateToSorteo = () => {
    navigate('/admin/sorteo');
  };
  const handleNavigateToSorteoActual = () => {
    navigate('/admin/sorteoactual');
  };
  const handleNavigateToAdminBarraDeProgreso = () => {
    navigate('/admin/adminbarradeprogreso');
  };
  const handleNavigateToBorrarBase = () => {
    navigate('/admin/borrarbase');
  };

  return (
    <div className="panel-admin">
      <h1 className="panel-title">Panel de Administración</h1>
      <div className="button-group">
        <button className="panel-button" onClick={handleNavigateToPublicacion}>
          Nuevo Sorteo
        </button>
        <button className="panel-button" onClick={handleNavigateToSorteoActual}>
          Datos Sorteo Actual
        </button>
        <button className="panel-button" onClick={handleNavigateToAdminBarraDeProgreso}>
          Barra de Progreso
        </button>
        <button className="panel-button" onClick={handleNavigateToSorteo}>
          Generar Sorteo
        </button>
        <button className="panel-button" onClick={handleNavigateToBorrarBase}>
          Borrar Base de Datos
        </button>
      </div>
    </div>
  );
};

export default PanelAdmin;
