  import React, { useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';

  const Success = () => {
    const navigate = useNavigate();

    useEffect(() => {
      // Puedes usar esta sección para manejar cualquier lógica adicional que necesites
      // por ejemplo, notificar al backend que el pago fue exitoso

      // Simulación de redirección después de unos segundos
      const timer = setTimeout(() => {
        navigate('/'); // Redirigir a la página principal después de 5 segundos
      }, 5000);

      // Limpieza de temporizador cuando el componente se desmonte
      return () => clearTimeout(timer);
    }, [navigate]);

    return (
      <div className="success-page">
        <h1>¡Pago Realizado con Éxito!</h1>
        <p>Gracias por tu compra. Tu pago ha sido aceptado.</p>
        <p>Serás redirigido a la página principal en unos segundos...</p>

        <button onClick={() => navigate('/')}>Volver a la página principal</button>
      </div>
    );
  };

  export default Success;
